export class User {
    id?: string;
    about: string;
    address: string;
    approved_email: boolean;
    city: string;
    district?: string;
    social_media_links?: string;
    number_of_followers?: string;
    cv_url?: string;
    online_diet_experience?: string;
    diet_experience?: string;
    appointment: {
        minutes: number;
        price: number;
        status: {
            chat: boolean;
            video: boolean;
            voice: boolean;
        }
    };
    birth_date: number;
    client: {
        last_login_ip: string;
        register_ip: string;
    };
    email: string;
    enabled: boolean;
    tax_information:{
        tax_number: string;
        adress: string;
    };
    firstname: string;
    is_doctor: boolean;
    is_online: boolean;
    lastname: string;
    display_name: string;
    sub_merchant_key: string;
    phone_number: string;
    profile_image: string;
    video: string;
    video_id: string;
    video_banner: string;
    questions: string[];
    pre_meeting: boolean;
    register_date: number;
    review: {
        average_point: number;
        total_evaluation: number;
        total_point: number;
    };
    role: 'doctor' | 'user' | 'psychologist' | 'nutritionist';
    bank_account?: {
      iban: string;
    };
    documents?: {
      school_certificate: string;
      bank_certificate: string;
      identification: string;
    };
    school?: string;
    uid?: string;
}
