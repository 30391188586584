// Angular
import { ChangeDetectorRef, Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import { first, map, filter } from 'rxjs/operators';
import { UserStore } from '@core/store/user.store';
import { MenuConfig } from "@core/_config/menu.config";
import { Manager } from "@core/models/manager.model";
import { UserQuery } from "@core/query/user.query";
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MenuAsideService {
  // Public properties
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  menu = [
    {
      title: 'Anasayfa',
      root: true,
      bullet: 'dot',
      page: '/dashboard'
    },
    {
      title: 'Diyetisyenler',
      root: true,
      bullet: 'dot',
      submenu: [
        {
          title: 'Listele',
          page: '/doctor-list'
        },
        {
          title: 'Ön Onay Bekleyenler',
          page: '/doctor-pre-waiting-list',
          badge: {
            type: 'kt-badge--bold kt-badge-chat',
            value: 'pre-waiting',
            count: this.loadPreWaiting()
          }
        },
        {
          title: 'Arşivlenmişler',
          page: '/archived',
          badge: {
            type: 'kt-badge--bold kt-badge-chat',
            value: 'archived',
            count: this.loadArchived()
          }
        },
        {
          title: 'Onay Bekleyenler',
          page: '/doctor-waiting-list',
          badge: {
            type: 'kt-badge--bold kt-badge-chat',
            value: 'waiting',
            count: this.loadWaiting()
          }
        },
        {
          title: 'Başvurular',
          page: '/nutritionist-application'
        },
        {
          title: 'Diyetisyen Kaydı',
          page: '/doctor-register'
        },
        {
          title: 'Leads',
          page: '/leads'
        },
      ],
      data: { field: 'doctors', type: 'read' },
    },
    {
      title: 'Danışanlar',
      root: true,
      page: '/patient-list',
      bullet: 'dot',
      data: { field: 'patients', type: 'read' },
      badge: {
        type: 'kt-badge--bold kt-badge-chat',
        value: 'pre-waiting',
        count: this.loadUserCount()
      }
    },
    {
      title: 'Potansiyel Müşteriler',
      root: true,
      bullet: 'dot',
      data: { field: 'potential_customers', type: 'read' },
      page: '/potential-customers/not-call-list',
    },
    {
      title: 'Takvim',
      root: true,
      page: '/calendar',
      bullet: 'dot',
      data: { field: 'calendar', type: 'read' },
      badge: {
        type: 'kt-badge--bold kt-badge-chat',
        value: 'pre-waiting',
        count: this.loadApptCount()
      }
    },
    {
      title: 'Kampanyalar',
      root: true,
      bullet: 'dot',
      data: { field: 'campaign', type: 'write' },
      submenu: [
        {
          title: 'Listele',
          root: true,
          bullet: 'dot',
          page: '/campaign-list',
        },
        {
          title: 'Ekle',
          root: true,
          bullet: 'dot',
          page: '/campaign-list/none',
        },
      ],
    },
    {
      title: 'Paket Siparişleri',
      root: true,
      page: '/orders-list',
      bullet: 'dot',
      data: { field: 'orders', type: 'write' },
      badge: {
        type: 'kt-badge--bold kt-badge-chat',
        value: 'pre-waiting',
        count: this.loadPackageCount()
      }
    },
    // {
    //   title: 'Seans Siparişleri',
    //   root: true,
    //   page: '/session-orders-list',
    //   bullet: 'dot',
    //   data: { field: 'session_orders', type: 'write' },
    //   badge: {
    //     type: 'kt-badge--bold kt-badge-chat',
    //     value: 'pre-waiting',
    //     count: this.loadSessionPackageCount()
    //   }
    // },
    {
      title: 'Diyet Programı Siparişleri',
      root: true,
      bullet: 'dot',
      page: '/orders-diet-program',
      data: { field: 'diet_program_orders', type: 'read' },
      badge: {
        type: 'kt-badge--bold kt-badge-chat',
        value: 'pre-waiting',
        count: this.loadProgramCount()
      },
    },
    {
      title: 'Paket Takibi',
      root: true,
      page: '/package-tracking',
      bullet: 'dot',
      data: { field: 'package_tracking', type: 'read' },
      badge: {
        type: 'kt-badge--bold kt-badge-chat',
        value: 'pre-waiting',
        count: this.loadOrders()
      }
    },
    {
      title: 'E-posta',
      root: true,
      bullet: 'dot',
      submenu: [
        {
          title: 'Listele',
          page: '/email/list',
        },
        {
          title: 'Ekle',
          page: '/email/edit',
        }
      ],
      data: { field: 'email', type: 'read' },
    },
    {
      title: 'Bildirimler',
      root: true,
      bullet: 'dot',
      submenu: [
        {
          title: 'Listele',
          page: '/notification-list'
        },
        {
          title: 'Bildirim Ekle',
          page: '/notification-add'
        },
      ],
      data: { field: 'notification', type: 'write' },
    },
    {
      title: 'Diyet Programı',
      root: true,
      bullet: 'dot',
      data: { field: 'diet_program', type: 'read' },
      badge: {
        type: 'kt-badge--bold kt-badge-chat',
        value: 'pre-waiting',
      },
      submenu: [
        {
          title: 'Liste',
          page: '/diet-program/list'
        },
        {
          title: 'Ödeme Bekleyen',
          page: '/waiting-diet-program'
        },
        {
          title: 'Ödenmiş',
          page: '/done-diet-program',
        },
        {
          title: 'Ücretsiz',
          page: '/free-diet-program',
        },
        {
          title: 'Yorumlar',
          page: '/diet-program-comments'
        },
      ],
    },
    {
      title: 'İstatistikler',
      page: '/stats',
      root: true,
      bullet: 'dot',
      data: { field: 'stats', type: 'read' }
    },
    {
      title: 'Promosyon Kodları',
      root: true,
      bullet: 'dot',
      submenu: [
        {
          title: 'Listele',
          page: '/code-list'
        },
        {
          title: 'Kod Oluştur',
          page: '/code-add'
        }
      ],
      data: { field: 'coupons', type: 'write' },
    },
    {
      title: 'Problemler',
      root: true,
      bullet: 'dot',
      data: { field: 'problems', type: 'read' },
      submenu: [
        {
          title: 'Listele',
          root: true,
          bullet: 'dot',
          page: '/problem-list',
        },
        {
          title: 'Problem Ekleme',
          root: true,
          bullet: 'dot',
          page: '/problem-add',
        },
        {
          title: 'Problem Soruları',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Listele',
              root: true,
              bullet: 'dot',
              page: '/problem-faq-list',
            },
            {
              title: 'Problem Sorusu Ekleme',
              root: true,
              bullet: 'dot',
              page: '/problem-faq-add',
            },
          ],
        },
      ],
    },
    {
      title: 'Kampanyalar',
      root: true,
      bullet: 'dot',
      data: { field: 'campaigns', type: 'write' },
      submenu: [
        {
          title: 'Listele',
          root: true,
          bullet: 'dot',
          page: '/campaigns/list',
        },
        {
          title: 'Yeni Kampanya',
          root: true,
          bullet: 'dot',
          page: '/campaigns/add',
        },
      ],
    },
    {
      title: 'Yönetim',
      root: true,
      bullet: 'dot',
      submenu: [
        {
          title: 'Partnerler',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Partner Listesi',
              page: '/partner-list'
            },
            {
              title: 'Partner Ekleme',
              page: '/partner-register',
            },
          ],
          data: { field: 'partners', type: 'read' },
        },
        {
          title: 'Görevliler',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Görevliler',
              page: '/manager-list',

            },
            {
              title: 'Görevli Kaydı',
              page: '/manager-register',
            },
          ],
          data: { field: 'managers', type: 'read' },
        },
        {
          title: 'Fatura',
          root: true,
          page: '/bill',
          bullet: 'dot',
          data: { field: 'bill', type: 'read' },
        },
      ]
    },
    {
      title: 'İçerikler',
      root: true,
      bullet: 'dot',
      submenu: [
        {
          title: 'Egzersiz Videoları',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Listele',
              page: '/exercise-videos'
            },
            {
              title: 'Oluştur',
              page: '/exercise-videos/add'
            }
          ],
          data: { field: 'exercise_videos', type: 'write' },
        },
        {
          title: 'Blog',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Listele',
              page: '/blog-list'
            },
            {
              title: 'Onay Bekleyenler',
              page: '/unapproved-blog-list'
            },
            {
              title: 'Gönderi Ekle',
              page: '/blog-add'
            }
          ],
          data: { field: 'blog', type: 'write' },
        },
        {
          title: 'Forum',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Onay Bekleyen Sorular',
              page: '/forum-list',
            },
            {
              title: 'Sorular',
              page: '/unanswered-forum-list',
            },
            {
              title: 'Soru Oluştur',
              page: '/forum-add',
            },
            {
              title: 'Soru Şikayet',
              page: '/complaint-question',
            },
            {
              title: 'Cevap Şikayet',
              page: '/complaint-answer',
            }
            ,
            {
              title: 'Cevap Şablonları',
              page: '/message-templates',
            }
          ],
          data: { field: 'forums', type: 'read' },
        },
        {
          title: 'Kategori',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Listele',
              page: '/category-list'
            },
            {
              title: 'Gönderi Ekle',
              page: '/category-add'
            }
          ],
          data: { field: 'cornerstone', type: 'write' },
        },
        {
          title: 'Youtube Videoları',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Listele',
              page: '/youtube-video-list'
            },
            {
              title: 'Video Ekle',
              page: '/youtube-video-add'
            }
          ],
          data: { field: 'youtube_video', type: 'write' },
        },
        {
          title: 'Besin',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Listele',
              page: '/food-list'
            },
            {
              title: 'Gönderi Ekle',
              page: '/food-add/none'
            }
          ],
          data: { field: 'foods', type: 'write' },

        },
        {
          title: 'Egzersiz',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Listele',
              page: '/exercise-list'
            },
            {
              title: 'Oluştur',
              page: '/exercise-add'
            }
          ],
          data: { field: 'exercise', type: 'write' },
        },
        {
          title: 'Sorular',
          root: true,
          page: '/questions',
          bullet: 'dot',
          data: { field: 'questions', type: 'read' },
          submenu: [
            {
              title: 'Sorular',
              page: '/questions',
              badge: {
                type: 'kt-badge--bold kt-badge-chat',
                value: 'pre-waiting',
                count: this.loadQuestions()
              },
            },
            {
              title: 'Arşivlenmiş Sorular',
              page: '/archved-questions'
            },
            {
              title: 'Onaylanmış Sorular',
              page: '/approved-questions'
            },
          ],
        },
        {
          title: 'Esenlik Aktiviteleri',
          root: true,
          page: '/well-being-list',
          bullet: 'dot',
          data: { field: 'well_being', type: 'read' },
        },
        {
          title: 'Bannerlar',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Listele',
              page: '/banner-content/list',
            },
            {
              title: 'Ekle',
              page: '/banner-content/edit',
            }
          ],
          data: { field: 'banner_content', type: 'read' },
        },
        {
          title: 'Uygulama Linkleri',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Listele',
              page: '/app-url/list'
            },
            {
              title: 'Oluştur',
              page: '/app-url/add'
            }
          ],
          data: { field: 'app_url', type: 'write' },
        },
      ]
    },
    {
      title: 'Geri Bildirimler',
      root: true,
      bullet: 'dot',
      submenu: [
        {
          title: 'Geri Bildirimler',
          root: true,
          page: '/contact-list',
          bullet: 'dot',
          data: { field: 'contact', type: 'read' },
          badge: {
            type: 'kt-badge--bold kt-badge-chat',
            value: 'pre-waiting',
            count: this.loadContactCount()
          }
        },
        {
          title: 'Geri Bildirim Şablonları',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Listele',
              page: '/message-list'
            },
            {
              title: 'Mesaj Şablonu Ekle',
              page: '/message-add'
            },
          ],
          data: { field: 'message_templates', type: 'write' },
        },
        {
          title: 'Landing Geri Bildirimler',
          root: true,
          page: '/landing-contact-list',
          bullet: 'dot',
          data: { field: 'landing_contact', type: 'read' },
          badge: {
            type: 'kt-badge--bold kt-badge-chat',
            value: 'pre-waiting',
            count: this.loadLandingContactCount()
          }
        },
        {
          title: 'Uygulama İncelemeleri',
          root: true,
          bullet: 'dot',
          submenu: [
            {
              title: 'Listele',
              page: '/reviews-list',
            }
          ],
          data: { field: 'review_app', type: 'read' },
        },
        {
          title: 'Yorumlar',
          root: true,
          page: '/comments-list',
          bullet: 'dot',
          data: { field: 'reviews', type: 'write' },
        },
      ]
    },
    // {
    //   title: 'Landing',
    //   root: true,
    //   bullet: 'dot',
    //   submenu: [
    //     {
    //       title: 'Listele',
    //       page: '/landing-page-list'
    //     },
    //     {
    //       title: 'Sayfa Ekle',
    //       page: '/landing-page-add'
    //     }
    //   ],
    //   data: { field: 'landing_pages', type: 'write' },
    // },
    // {
    //   title: 'Destek',
    //   root: true,
    //   bullet: 'dot',
    //   submenu: [
    //     {
    //       title: 'Listele',
    //       page: '/support-list',

    //     },
    //     {
    //       title: 'Destek Kaydı',
    //       page: '/support-create',
    //     },
    //   ],
    //   data: { field: 'support', type: 'read' },
    // },
    {
      title: 'Bülten',
      root: true,
      page: '/newsletter-list',
      bullet: 'dot',
      data: { field: 'newsletter', type: 'read' },
    },
    {
      title: 'Çıkış Yap',
      root: true,
      page: '/auth/login'
    },
  ];

  constructor(private fireStore: AngularFirestore) {
  }

  loadMenu(user: Manager) {
    const menu = this.menu.filter((menu) => {
      if (menu.data && !user.permissions[menu.data.field]) return false;
      return menu.data ? user.permissions[menu.data.field][menu.data.type] : true;
    });
    console.log("Menu data: ", menu);
    this.menuList$.next(menu);
    console.log(this.menuList$.value);
  }

  private loadUserCount() {
    return this.fireStore.collection('general').doc('count').valueChanges().pipe(
      map((value) => value['patient'])
    );
  }

  private loadContactCount() {
    return this.fireStore.collection('general').doc('count').valueChanges().pipe(
      map((value) => value['contact'])
    );
  }

  private loadLandingContactCount() {
    return this.fireStore.collection('general').doc('count').valueChanges().pipe(
      map((value) => value['landing_contact'])
    );
  }

  private loadOrders() {
    return this.fireStore.collection('general').doc('count').valueChanges().pipe(
      map((value) => value['approved_order'])
    );
  }

  private loadQuestions() {
    return this.fireStore.collection('general').doc('count').valueChanges().pipe(
      map((value) => value['question'])
    );
  }

  private loadApptCount() {
    const now = firebase.firestore.Timestamp.now().toDate();
    const start = moment(now).startOf('days').toDate().getTime();
    const end = moment(now).endOf('days').toDate().getTime();

    return this.fireStore.collection('appointments', (query) =>
      query.where('date', '>=', start)
        .where('date', '<=', end)
        .where('assigned', '==', true)
    ).valueChanges().pipe(
      map((value) => {
        return value.filter((snap) => snap['assigned_uid'] !== 'none').length
      })
    );
  }

  private loadPackageCount() {
    const now = firebase.firestore.Timestamp.now().toDate();
    const start = moment(now).startOf('month').toDate().getTime();
    const end = moment(now).endOf('month').toDate().getTime();

    return this.fireStore.collection('orders', (query) =>
      query.where('create_date', '>=', start)
        .where('create_date', '<=', end)
        .where('status', '==', 'approved')
    ).valueChanges().pipe(
      map((value) => {
        return value.filter((query) => query['refund_amount'] !== query['amount'])
          .filter((query) => !query['gift_code'])
          .filter((snap) => snap['author_uid'] !== 'none').length
      })
    );
  }

  private loadSessionPackageCount() {
    const now = firebase.firestore.Timestamp.now().toDate();
    const start = moment(now).startOf('month').toDate().getTime();
    const end = moment(now).endOf('month').toDate().getTime();

    return this.fireStore.collection('psychologist_orders', (query) =>
      query.where('create_date', '>=', start)
        .where('create_date', '<=', end)
        .where('status', '==', 'approved')
    ).valueChanges().pipe(
      map((value) => {
        return value.filter((query) => query['refund_amount'] !== query['amount'])
          .filter((query) => !query['gift_code'])
          .filter((snap) => snap['author_uid'] !== 'none').length
      })
    );
  }


  private loadProgramCount() {
    const now = firebase.firestore.Timestamp.now().toDate();
    const start = moment(now).startOf('month').toDate().getTime();
    const end = moment(now).endOf('month').toDate().getTime();

    return this.fireStore.collection('iap_orders', (query) =>
      query.where('create_date', '>=', start)
        .where('create_date', '<=', end)
        .where('approved', '==', true)
        .where('is_free', '==', false)
    ).valueChanges().pipe(
      map((value) => {
        return value.filter((query) => !query['is_refunded']).length;
      })
    );
  }

  private loadPreWaiting() {
    return this.fireStore.collection('users', (query) =>
      query.where('is_doctor', '==', true)
        .where('pre_approved', '==', false)
        .where('is_archived', '==', false)
    ).valueChanges().pipe(
      map((value) => value.length)
    );
  }

  private loadArchived() {
    return this.fireStore.collection('users', (query) =>
      query.where('is_doctor', '==', true)
        .where('pre_approved', '==', false)
        .where('is_archived', '==', true)
    ).valueChanges().pipe(
      map((value) => value.length)
    );
  }

  private loadWaiting() {
    return this.fireStore.collection('users', (query) =>
      query.where('is_doctor', '==', true)
        .where('pre_approved', '==', true)
        .where('approved', '==', false)
    ).valueChanges().pipe(
      map((value) => value.length)
    );
  }
}
