// export const environment = {
//   production: false,
//   isMockEnabled: true, // You have to switch this, when your real back-end is done
//   authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
//   ipURL: 'https://www.askipo.com/api/ip',
//   cloudFunctionUrl: 'https://us-central1-askhippo-development.cloudfunctions.net/',
//   sqlApi: 'https://maradona.askipo.com/api.development/'
//   // sqlApi: 'https://maradona.askipo.com/api.stage/',
//   // sqlApi: 'https://askipo.com/api/'
// };

// export const firebaseConfig = {
//   apiKey: 'AIzaSyAptyqUzFdzuhsO7WQWXUQfbqQDr2Xm50o',
//   authDomain: 'askhippo-development.firebaseapp.com',
//   databaseURL: 'https://askhippo-development.firebaseio.com',
//   projectId: 'askhippo-development',
//   storageBucket: 'askhippo-development.appspot.com',
//   messagingSenderId: '914822170849',
//   appId: '1:914822170849:web:698dbf7804636986da8fa0'
// };

// export const apiURL = 'https://us-central1-askhippo-development.cloudfunctions.net/';

// export const statusesURL = 'https://maradona.askipo.com/api.development/outbound/statuses';

// export const tagURL = 'https://maradona.askipo.com/api.development/outbound/tags'


export const environment = {
  production: true,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  ipURL: 'https://www.askipo.com/api/ip',
  cloudFunctionUrl: 'https://us-central1-askipo-production.cloudfunctions.net/',
  sqlApi: 'https://askipo.com/api/',
  // sqlApi: 'https://maradona.askipo.com/api.development/'
};
export const firebaseConfig = {
  apiKey: 'AIzaSyBvwa8G2iTpLyzBsusdwbFemJ28fyswfko',
  authDomain: 'askipo-production.firebaseapp.com',
  databaseURL: 'https://askipo-production.firebaseio.com',
  projectId: 'askipo-production',
  storageBucket: 'askipo-production.appspot.com',
  messagingSenderId: '1061987882152',
  appId: '1:1061987882152:web:ac357395c5f1f243921e9d',
  measurementId: 'G-9CNZ0SE0G8'
};

export const apiURL = 'https://us-central1-askipo-production.cloudfunctions.net/';
export const statusesURL = 'https://maradona.askipo.com/api.development/outbound/statuses';

export const tagURL = 'https://maradona.askipo.com/api.development/outbound/tags'
