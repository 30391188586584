import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  apiUrl: string = 'https://jsonplaceholder.typicode.com/'

  constructor(
    private http: HttpClient
  ) { }

}
