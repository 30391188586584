import { UserQuery } from './../../../core/query/user.query';
import { interval, Observable } from 'rxjs';
// Angular
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';
// Object-Path
import * as objectPath from 'object-path';
// Loading bar
import { LoadingBarService } from '@ngx-loading-bar/core';
// Layout
import { LayoutConfigService, LayoutRefService } from '../../../core/_base/layout';
// HTML Class Service
import { HtmlClassService } from '../html-class.service';
import { UserService } from '@core/services/user.service';
import firebase from 'firebase/app';
import moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { addMinutes, addSeconds } from 'date-fns';


@Component({
  selector: 'kt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  // Public properties
  menuHeaderDisplay: boolean;
  fluid: boolean;

  name$: Observable<string>;

  url: string;

  toggleNotifications = false;

  notifications: Observable<any[]>;

  now;

  isDoctor$: Observable<boolean>;

  private clock$: Observable<Date>;

  @ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private layoutRefService: LayoutRefService,
    private layoutConfigService: LayoutConfigService,
    private query: UserQuery,
    public loader: LoadingBarService,
    public htmlClassService: HtmlClassService,
    private userService: UserService
  ) {
    this.url = this.headerText(this.router.url);

    this.isDoctor$ = this.query.is_doctor$;
    // page progress bar percentage
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // set page progress bar loading to start on NavigationStart event router
        this.loader.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loader.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loader.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // set page progress bar loading to end on NavigationEnd event router
        this.loader.complete();
        this.url = this.headerText(event.url);
      }
    });
  }

  headerText(path: string) {
    if (path.includes('doctor-list')) {
      return 'Diyetisyenler';
    }
    else if (path.includes('dashboard')) {
      return 'Anasayfa';
    } else if (path.includes('doctor-register')) {
      return 'Diyetisyen Kaydı';
    } else if (path.includes('doctor-detail')) {
      return 'Diyetisyen Detayları';
    } else if (path.includes('patient-list')) {
      return 'Danışan Listesi';
    } else if (path.includes('employee-register')) {
      return 'Görevli Kaydı';
    } else if (path.includes('message-list')) {
      return 'Mesaj Şablonu Listesi';
    } else if (path.includes('notification-list')) {
      return 'Bildirim Listesi';
    } else {
      return 'Askipo Admin';
    }
  }

  time(now) {
    const format = 'hh:mm:ss';
    const time = moment(now, format);

    const morning = moment('02:00:00', format);
    const day = moment('12:00:00', format);
    const night = moment('19:00:00', format);

    if (time.isBetween(morning, day)) {
      return 'Günaydın';
    }
    if (time.isBetween(day, night)) {
      return 'İyi günler';
    }
    return 'İyi akşamlar';


  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();

    this.now = firebase.firestore.Timestamp.now().toDate();

    this.clock$ = interval(1000).pipe(
      startWith(this.now),
      map((tick) => this.now = addSeconds(this.now, 1))
    );

    // get menu header display option
    this.menuHeaderDisplay = objectPath.get(config, 'header.menu.self.display');

    // header width fluid
    this.fluid = objectPath.get(config, 'header.self.width') === 'fluid';

    this.name$ = this.query.name$;

    // animate the header minimize the height on scroll down. to be removed, not applicable for default demo
    /*if (objectPath.get(config, 'header.self.fixed.desktop.enabled') || objectPath.get(config, 'header.self.fixed.desktop')) {
        // header minimize on scroll down
        this.ktHeader.nativeElement.setAttribute('data-ktheader-minimize', '1');
      }*/
  }

  ngAfterViewInit(): void {
    // keep header element in the service
    this.layoutRefService.addElement('header', this.ktHeader.nativeElement);
  }
}
