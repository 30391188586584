import { RoleGuard } from './core/auth/_guards/role.guard';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth Guard
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
// import {PackageGuard} from '@core/auth/_guards/package.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/auth/login']);

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },

  {
    path: '',
    component: BaseComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: 'doctor-register',
        loadChildren: () => import('./views/pages/content/doctor/register/doctor-register.module').then(m => m.DoctorRegisterModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'doctors' }
      },
      {
        path: 'food-add/:id',
        loadChildren: () => import('./views/pages/content/food/food-add/food-add.module').then(m => m.FoodAddModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'foods' }
      },
      {
        path: 'food-list',
        loadChildren: () => import('./views/pages/content/food/food-list/food-list.module').then(m => m.FoodListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'foods' }
      },
      {
        path: 'contest-leaderboard',
        loadChildren: () => import('./views/pages/content/contest-leaderboard/contest-leaderboard.module')
          .then(m => m.ContestLeaderboardModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'contest_leaderboard' }
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/content/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'doctor-list',
        loadChildren: () => import('./views/pages/content/doctor/list/doctor-list.module').then(m => m.DoctorListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'doctors' }
      },
      {
        path: 'patient-list',
        loadChildren: () => import('./views/pages/content/patient/list/patient-list.module').then(m => m.PatientListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'patients' }
      },
      {
        path: 'potential-customers',
        children: [
          {
            path: 'not-call-list',
            loadChildren: () => import('./views/pages/content/potential-customers/not-sought-list/not-sought-list.module').then(m => m.NotSoughtListModule),
            canActivate: [RoleGuard],
            data: { type: 'read', field: 'patients' }
          },
          {
            path: 'call-list',
            loadChildren: () => import('./views/pages/content/potential-customers/sought-list/sought-list.module').then(m => m.SoughtListModule),
            canActivate: [RoleGuard],
            data: { type: 'read', field: 'patients' }
          },
          {
            path: 'detail/:uid/:outboundId',
            loadChildren: () => import('./views/pages/content/potential-customers/detail/potential-patient-detail.module').then(m => m.PotentialPatientDetailModule),
            canActivate: [RoleGuard],
            data: { type: 'read', field: 'patients' }
          },
        ]
      },
      {
        path: 'app-url',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./views/pages/content/app-url/list/app-url-list.module').then(m => m.AppUrlListModule),
            canActivate: [RoleGuard],
            data: { type: 'read', field: 'app_urls' }
          },
          {
            path: 'add',
            loadChildren: () => import('./views/pages/content/app-url/add/app-url-add.module').then(m => m.AppUrlAddModule),
            canActivate: [RoleGuard],
            data: { type: 'write', field: 'app_urls' }
          },
          {
            path: 'edit/:id',
            loadChildren: () => import('./views/pages/content/app-url/edit/app-url-edit.module').then(m => m.AppUrlEditModule),
            canActivate: [RoleGuard],
            data: { type: 'write', field: 'app_urls' }
          },
        ]
      },
      {
        path: 'doctor-waiting-list',
        loadChildren: () => import('./views/pages/content/doctor/waiting-list/doctor-waiting-list.module').then(m => m.DoctorWaitingListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'doctors' }
      },
      {
        path: 'doctor-pre-waiting-list',
        loadChildren: () => import('./views/pages/content/doctor/pre-waiting-list/doctor-pre-waiting-list.module').then(m => m.DoctorPreWaitingListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'doctors' }
      },
      {
        path: 'archived',
        loadChildren: () =>
          import('./views/pages/content/doctor/archived/archived.module')
            .then(m => m.ArchivedModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'doctors' }
      },
      {
        path: 'nutritionist-application',
        loadChildren: () =>
          import('./views/pages/content/doctor/nutritionist-application/nutritionist-application.module')
            .then(m => m.NutritionistApplicationModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'doctors' }
      },
      {
        path: 'nutritionist-application/detail/:phone',
        loadChildren: () =>
          import('./views/pages/content/doctor/nutritionist-application/detail/detail.module')
            .then(m => m.DetailModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'doctors' }
      },
      {
        path: 'partner-list',
        loadChildren: () => import('./views/pages/content/partner/list/partner-list.module').then(m => m.PartnerListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'partners' },
      },
      {
        path: 'partner-register',
        loadChildren: () => import('./views/pages/content/partner/register/partner-register.module').then(m => m.PartnerRegisterModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'partners' }
      },
      {
        path: 'partner-edit/:id',
        loadChildren: () => import('./views/pages/content/partner/edit/partner-edit.module').then(m => m.PartnerEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'partners' }
      },
      {
        path: 'patient-detail/:uid',
        loadChildren: () => import('./views/pages/content/patient/detail/patient-detail.module').then(m => m.PatientDetailModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'patients' }
      },
      {
        path: 'doctor-detail/:uid',
        loadChildren: () => import('./views/pages/content/doctor/detail/doctor-detail.module').then(m => m.DoctorDetailModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'doctors' }
      },
      {
        path: 'orders-diet-program',
        loadChildren: () => import('./views/pages/content/diet-program-orders/diet-program-orders.module')
          .then(m => m.DietProgramOrdersModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'diet_program_orders' }
      },
      {
        path: 'waiting-diet-program',
        loadChildren: () => import('./views/pages/content/diet-program-orders-waiting/diet-program-orders-waiting.module')
          .then(m => m.DietProgramOrdersWaitingModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'diet_program_orders' }
      },
      {
        path: 'done-diet-program',
        loadChildren: () => import('./views/pages/content/diet-program-orders-done/diet-program-orders-done.module')
          .then(m => m.DietProgramOrdersDoneModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'diet_program_orders' }
      },
      {
        path: 'free-diet-program',
        loadChildren: () => import('./views/pages/content/diet-program-orders-free/diet-program-orders-free.module')
          .then(m => m.DietProgramOrdersFreeModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'diet_program_orders' }
      },
      {
        path: 'comments-list',
        loadChildren: () => import('./views/pages/content/comments/list/comments-list.module').then(m => m.CommentsListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'reviews' },
      },
      {
        path: 'diet-program-comments',
        loadChildren: () => import('./views/pages/content/diet-program-comments/diet-program-comments.module')
          .then(m => m.DietProgramCommentsModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'diet_program_comments' },
      },
      {
        path: 'orders-list',
        loadChildren: () => import('./views/pages/content/orders/list/orders-list.module').then(m => m.OrdersListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'orders' },
      },
      {
        path: 'session-orders-list',
        loadChildren: () => import('./views/pages/content/session-orders/session-orders.module').then(m => m.SessionOrdersModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'session_orders' },
      },
      {
        path: 'package-tracking',
        loadChildren: () => import('./views/pages/content/package-tracking/package-tracking.module').then(m => m.PackageTrackingModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'package_tracking' },
      },
      {
        path: 'manager-register',
        loadChildren: () => import('./views/pages/content/employee/register/employee-register.module').then(m => m.EmployeeRegisterModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'managers' },
      },
      {
        path: 'manager-list',
        loadChildren: () => import('./views/pages/content/employee/list/employee-list.module').then(m => m.EmployeeListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'managers' },
      },
      {
        path: 'manager-detail/:id',
        loadChildren: () => import('./views/pages/content/employee/detail/employee-detail.module').then(m => m.EmployeeDetailModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'managers' },
      },
      {
        path: 'manager-edit/:id',
        loadChildren: () => import('./views/pages/content/employee/edit/employee-edit.module').then(m => m.EmployeeEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'managers' },
      },
      {
        path: 'well-being-list',
        loadChildren: () => import('./views/pages/content/well-being/list/well-being-list.module').then(m => m.WellBeingListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'well_being' },
      },
      {
        path: 'well-being-edit/:id',
        loadChildren: () => import('./views/pages/content/well-being/edit/well-being-edit.module').then(m => m.WellBeingEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'well_being' },
      },
      // ---
      {
        path: 'youtube-video-list',
        loadChildren: () => import('./views/pages/content/youtube-video/list/youtube-video-list.module').then(m => m.YoutubeVideoListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'youtube_video' },
      },
      {
        path: 'youtube-video-add',
        loadChildren: () => import('./views/pages/content/youtube-video/add/youtube-video-add.module').then(m => m.YoutubeVideoAddModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'youtube_video' },
      },
      {
        path: 'youtube-video-edit/:id',
        loadChildren: () => import('./views/pages/content/youtube-video/edit/youtube-video-edit.module').then(m => m.YoutubeVideoEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'youtube_video' },
      },

      {
        path: 'landing-page-list',
        loadChildren: () => import('./views/pages/content/landing-page/list/landing-page-list.module').then(m => m.LandingPageListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'landing_pages' },
      },
      {
        path: 'landing-page-add',
        loadChildren: () => import('./views/pages/content/landing-page/add/landing-page-add.module').then(m => m.LandingPageAddModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'landing_pages' },
      },
      {
        path: 'landing-page-edit/:id',
        loadChildren: () => import('./views/pages/content/landing-page/edit/landing-page-edit.module').then(m => m.LandingPageEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'landing_pages' },
      },

      // ---
      {
        path: 'code-list',
        loadChildren: () => import('./views/pages/content/codes/list/code-list.module').then(m => m.CodeListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'coupons' },
      },
      {
        path: 'code-add',
        loadChildren: () => import('./views/pages/content/codes/add/code-add.module').then(m => m.CodeAddModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'coupons' },
      },
      {
        path: 'code-edit/:id',
        loadChildren: () => import('./views/pages/content/codes/edit/code-edit.module').then(m => m.CodeEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'coupons' },
      },
      {
        path: 'blog-list',
        loadChildren: () => import('./views/pages/content/blog/list/blog-list.module').then(m => m.BlogListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'blog' },
      },
      {
        path: 'unapproved-blog-list',
        loadChildren: () => import('./views/pages/content/blog/list-v2/blog-list.module').then(m => m.BlogListV2Module),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'blog' },
      },
      {
        path: 'blog-add',
        loadChildren: () => import('./views/pages/content/blog/add/blog-add.module').then(m => m.BlogAddModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'blog' },
      },
      {
        path: 'blog-edit/:id',
        loadChildren: () => import('./views/pages/content/blog/edit/blog-edit.module').then(m => m.BlogEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'blog' },
      },
      {
        path: 'contact-list',
        loadChildren: () => import('./views/pages/content/contact/list/contact-list.module').then(m => m.ContactListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'contact' }
      },
      {
        path: 'landing-contact-list',
        loadChildren: () => import('./views/pages/content/contact/landing-list/contact-landing-list.module').then(m => m.ContactLandingListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'landing_contact' }
      },
      {
        path: 'newsletter-list',
        loadChildren: () => import('./views/pages/content/newsletter/list/newsletter-list.module').then(m => m.NewsletterListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'newsletter' }
      },
      {
        path: 'support-create',
        loadChildren: () => import('./views/pages/content/support/create/support-create.module').then(m => m.SupportCreateModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'support' }
      },
      {
        path: 'support-list',
        loadChildren: () => import('./views/pages/content/support/list/support-list.module').then(m => m.SupportListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'support' }
      },
      {
        path: 'support-edit/:id',
        loadChildren: () => import('./views/pages/content/support/edit/support-edit.module').then(m => m.SupportEditModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'support' }
      },
      {
        path: 'calendar',
        loadChildren: () => import('./views/pages/content/calendar/general-calendar.module').then(m => m.GeneralCalendarModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'calendar' }
      },
      {
        path: 'stats',
        loadChildren: () => import('./views/pages/content/stats/stats.module').then(m => m.StatsModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'stats' }
      },
      {
        path: 'stats-diet-program',
        loadChildren: () => import('./views/pages/content/stats-diet-program/stats-diet-program.module').then(m => m.StatsDietProgramModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'stats' }
      },
      {
        path: 'bill',
        loadChildren: () => import('./views/pages/content/bill/bill.module').then(m => m.BillModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'bill' }
      },
      {
        path: 'category-list',
        loadChildren: () => import('./views/pages/content/category/list/category-list.module').then(m => m.CategoryListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'cornerstone' },
      },
      {
        path: 'category-add',
        loadChildren: () => import('./views/pages/content/category/add/category-add.module').then(m => m.CategoryAddModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'cornerstone' },
      },
      {
        path: 'category-edit/:id',
        loadChildren: () => import('./views/pages/content/category/edit/category-edit.module').then(m => m.CategoryEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'cornerstone' },
      },
      {
        path: 'message-list',
        loadChildren: () => import('./views/pages/content/message/list/message-list.module').then(m => m.MessageListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'message_templates' },
      },
      {
        path: 'diet-program',
        loadChildren: () => import('./views/pages/content/diet-program/diet-program.module').then(m => m.DietProgramModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'diet_program' },
      },
      {
        path: 'exercise-videos',
        loadChildren: () => import('./views/pages/content/exercise-videos/list/video-list.module').then(m => m.VideoListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'exercise_videos' }
      },
      {
        path: 'exercise-videos/add',
        loadChildren: () => import('./views/pages/content/exercise-videos/add/video-add.module').then(m => m.VideoAddModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'exercise_videos' }
      },
      {
        path: 'exercise-videos/edit/:id',
        loadChildren: () => import('./views/pages/content/exercise-videos/add/video-add.module').then(m => m.VideoAddModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'exercise_videos' }
      },
      {
        path: 'questions',
        loadChildren: () => import('./views/pages/content/questions/list/question-list.module').then(m => m.QuestionListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'questions' }
      },
      {
        path: 'archved-questions',
        loadChildren: () => import('./views/pages/content/questions/archived-list/question-archived-list.module')
          .then(m => m.QuestionArchivedListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'questions' }
      },
      {
        path: 'approved-questions',
        loadChildren: () => import('./views/pages/content/questions/approved-list/question-approved-list.module')
          .then(m => m.QuestionApprovedListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'questions' }
      },
      {
        path: 'message-add',
        loadChildren: () => import('./views/pages/content/message/add/message-add.module').then(m => m.MessageAddModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'message_templates' },
      },
      {
        path: 'message-edit/:id',
        loadChildren: () => import('./views/pages/content/message/edit/message-edit.module').then(m => m.MessageEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'message_templates' },
      },
      {
        path: 'notification-list',
        loadChildren: () => import('./views/pages/content/notification/list/notification-list.module').then(m => m.NotificationListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'notification' },
      },
      {
        path: 'notification-add',
        loadChildren: () => import('./views/pages/content/notification/add/notification-add.module').then(m => m.NotificationAddModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'notification' },
      },
      {
        path: 'notification-add/:id',
        loadChildren: () => import('./views/pages/content/notification/add/notification-add.module').then(m => m.NotificationAddModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'notification', },
      },
      {
        path: 'exercise-list',
        loadChildren: () => import('./views/pages/content/exercise/list/exercise-list.module').then(m => m.ExerciseListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'exercise' },
      },
      {
        path: 'exercise-add',
        loadChildren: () => import('./views/pages/content/exercise/add/exercise-add.module').then(m => m.ExerciseAddModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'exercise' },
      },
      {
        path: 'reviews-list',
        loadChildren: () => import('./views/pages/content/reviews-app/list/reviews-list.module').then(m => m.ReviewsListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'review_app', },
      },
      {
        path: 'forum-list',
        loadChildren: () => import('./views/pages/content/forum/list/forum-list.module').then(m => m.ForumListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'forums', },
      },
      {
        path: 'unanswered-forum-list',
        loadChildren: () => import('./views/pages/content/forum/unanswered-list/forum-list.module').then(m => m.ForumListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'forums', },
      },
      {
        path: 'forum-add',
        loadChildren: () => import('./views/pages/content/forum/question-add/question-add.module').then(m => m.QuestionAddModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'forums', },
      },
      {
        path: 'message-templates',
        loadChildren: () => import('./views/pages/content/forum/message-templates/message-templates.module').then(m => m.MessageTemplatesModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'forums' },
      },
      {
        path: 'complaint-question',
        loadChildren: () => import('./views/pages/content/complaints/complaint-question-list/complaint-question-list.module').then(m => m.ComplaintQuestionListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'complaint', },
      },
      {
        path: 'complaint-answer',
        loadChildren: () => import('./views/pages/content/complaints/complaint-answer-list/complaint-answer-list.module').then(m => m.ComplaintAnswerListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'complaint', },
      },
      {
        path: 'banner-content',
        loadChildren: () => import('./views/pages/content/banner-contents/banner-contents.module').then(m => m.BannerContentsModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'banner_content' }
      },
      {
        path: 'email',
        loadChildren: () => import('./views/pages/content/email/email.module').then(m => m.EmailModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'email', }
      },
      {
        path: 'problem-list',
        loadChildren: () => import('./views/pages/content/problem/problem-list/problem-list.module').then(m => m.ProblemListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'problems', }
      },
      {
        path: 'problem-add',
        loadChildren: () => import('./views/pages/content/problem/problem-add-edit/problem-add-edit.module').then(m => m.ProblemAddEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'problems', }
      },
      {
        path: 'problem-edit/:id',
        loadChildren: () => import('./views/pages/content/problem/problem-add-edit/problem-add-edit.module').then(m => m.ProblemAddEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'problems', }
      },
      {
        path: 'problem-faq-list',
        loadChildren: () => import('./views/pages/content/problem/problemfaq/problem-faq-list/problem-faq-list.module').then(m => m.ProblemFaqListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'problems', }
      },
      {
        path: 'problem-faq-add',
        loadChildren: () => import('./views/pages/content/problem/problemfaq/problem-faq-add-edit/problem-faq-add-edit.module').then(m => m.ProblemFaqAddEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'problems', }
      },
      {
        path: 'problem-faq-edit/:id',
        loadChildren: () => import('./views/pages/content/problem/problemfaq/problem-faq-add-edit/problem-faq-add-edit.module').then(m => m.ProblemFaqAddEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'problems', }
      },
      {
        path: 'campaign-list',
        loadChildren: () => import('./views/pages/content/campaign/campaign-list/campaign-list.module').then(m => m.CampaignListModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'campaign', }
      },{
        path: 'campaign-list/:id',
        loadChildren: () => import('./views/pages/content/campaign/campaign-page/campaign-page.module').then(m => m.CampaignPageModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'campaign', }
      },
      {
        path: 'campaigns/list',
        loadChildren: () => import('./views/pages/content/campaigns/list/list.module').then(m => m.CampaignListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'campaigns', }
      },
      {
        path: 'campaigns/add',
        loadChildren: () => import('./views/pages/content/campaigns/add-edit/add-edit.module').then(m => m.CampaignAddEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'campaigns', }
      },
      {
        path: 'campaigns/edit/:id',
        loadChildren: () => import('./views/pages/content/campaigns/add-edit/add-edit.module').then(m => m.CampaignAddEditModule),
        canActivate: [RoleGuard],
        data: { type: 'write', field: 'campaigns', }
      },
      {
        path: 'leads',
        loadChildren: () =>
          import('./views/pages/content/doctor/leads/leads.module')
            .then(m => m.LeadsModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'doctors' }
      },
      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
        },
      },
      { path: 'error/:type', component: ErrorPageComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },

  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
